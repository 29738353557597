import { HttpClient, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Paginated, TableQueryParams, mapToFilterables, prepareQueryParams } from '@chapo/shared-utils';
import { injectMutation, injectQuery, injectQueryClient, toPromise } from '@ngneat/query';
import { ComponentStore } from '@ngrx/component-store';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { filter, of, switchMap, throwError } from 'rxjs';
import { Procedure } from './../data-access/procedure.type';

interface ProceduresState {}

@Injectable({
  providedIn: 'root',
})
export class ProceduresService extends ComponentStore<ProceduresState> {
  private http = inject(HttpClient);
  private queryClient = injectQueryClient();
  private query = injectQuery();
  private mutation = injectMutation();

  constructor() {
    super({});
  }

  getAll(queryParams: TableQueryParams) {
    const endpoint = `/backend/api/procedures`;
    const queryString = prepareQueryParams(queryParams, (tableFilters) => this.mapToProcedureFilters(tableFilters));

    return this.query({
      queryKey: ['procedures', queryParams] as const,
      queryFn: ({ signal }) => {
        const source = this.http.get<Paginated<Procedure>>(
          queryString.length > 0 ? `${endpoint}?${queryString}` : endpoint,
        );

        return toPromise({ source, signal });
      },
    });
  }

  getById(procedureId: Procedure['id']) {
    return this.query({
      queryKey: ['procedures', procedureId] as const,
      queryFn: ({ signal }) => {
        const source = this.http.get<Procedure>(`/backend/api/procedures/${procedureId}`);

        return toPromise({ source, signal });
      },
    });
  }

  uploadFiles() {
    return this.mutation({
      mutationFn: ({ files }: { files: NzUploadFile[] }) => {
        const formData = new FormData();

        files.forEach((file) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formData.append('files[]', file as any);
        });

        const request = new HttpRequest('POST', '/backend/api/procedures/uploads', formData, {
          reportProgress: true,
        });

        return this.http.request(request).pipe(
          filter((e) => e instanceof HttpResponse),
          switchMap((response) => {
            if (response instanceof HttpResponse) {
              const body = (response as HttpResponse<{ errors: string[] }>).body ?? { errors: [] };

              return of(body);
            }

            return throwError(() => response);
          }),
        );
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['procedures'] });
      },
    });
  }

  private mapToProcedureFilters(queryParamsFilters: NonNullable<TableQueryParams['filter']>): string[] {
    return mapToFilterables(queryParamsFilters, {});
  }
}
